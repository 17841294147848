<template lang="pug">
div
  v-overlay(
    absolute
    :value="orderGetting"
  )
    v-progress-circular(
      indeterminate
      size="64"
    )
  base-snackbar(
    :errors.sync="orderGetErrors"
  )
  v-toolbar.mb-5(
    color="primary"
    dark
    rounded
    dense
  )
    span Order Details
    v-spacer
    v-btn.mr-2(
      color="error"
      dark
      x-small
      @click="$router.push({ name: 'orders.table' })"
    )
      span back
    v-btn.mr-2(
      v-if="updatable"
      color="purple darken-3"
      dark
      x-small
      @click="$router.push({ name: 'orders.edit', params: { orderId: $route.params.orderId } })"
    )
      span Update Status
    v-btn.mr-2(
      color="purple darken-3"
      dark
      x-small
      @click="generatePdf"
      :loading="orderPrintReceiptGetting"
    )
      span Generate PDF
    v-btn.mr-2(
      color="purple darken-3"
      dark
      x-small
      @click="generateBillingStatement"
      :loading="gettingBillingStatement"
    )
      span Billing Statement
    v-btn(
      color="purple darken-3"
      dark
      x-small
      @click="paymentTransactions = true"
    )
      span ADD TRANSACTION
  v-row
    v-col(cols="12")
      v-alert(
        v-if="orderData.orderStatusId === 'CP'"
        type="success"
      )
        span Complete
      v-alert(
        v-else-if="orderData.orderStatusId === 'PL'"
        type="info"
      )
        span Parially Paid
      v-alert(
        v-else-if="overDue < 0"
        type="error"
      )
        span Invoice is overdue
      v-alert(
        v-else-if="overDue < 3"
        type="warning"
      )
        span invoice is almost due
    v-col(cols="3")
      v-card
        v-card-subtitle.primary--text.text-uppercase
          | Order Id
          span(v-if="orderData.poNumber") &nbsp;: PO# - {{ orderData.poNumber }}
        v-card-text {{ orderData.id }}

    v-col(cols="3")
      v-card
        v-card-subtitle.primary--text.text-uppercase Customer
        v-card-text {{ orderData.customer ? $autoAbbriviate(orderData.customer.company) : null || 'N/A' }}

    v-col(cols="3")
      v-card
        v-card-subtitle.primary--text.text-uppercase Project Code
        v-card-text(v-if="orderData.projectCode") {{ orderData.projectCode.project_code }}

    v-col(cols="3")
      v-card
        v-card-subtitle.primary--text.text-uppercase Address
        v-card-text {{ orderData.address }}

    v-col(cols="3")
      v-card
        v-card-subtitle.primary--text.text-uppercase Invoice No.
        v-card-text {{ $intToInvoiceNumber(invoice.id) }}

    v-col(cols="3")
      v-card
        v-card-subtitle.primary--text.text-uppercase Invoice Date
        v-card-text {{ (invoice.invoice_date || '').dateFormat() }}

    v-col(cols="3")
      v-card
        v-card-subtitle.primary--text.text-uppercase Date Due
        v-card-text {{ (dueDate || '').dateFormat() }}

    v-col(cols="3")
      v-card
        v-card-subtitle.primary--text.text-uppercase Amount Due
        v-card-text {{ amountDue.phpFormat() }}

    v-col(cols="2")
      v-card
        v-card-subtitle.primary--text.text-uppercase Date Delivered
        v-card-text {{ (orderData.deliveryDate || '').dateFormat() }}

    v-col(cols="2")
      v-card
        v-card-subtitle.primary--text.text-uppercase Date Delivered
        v-card-text {{ (orderData.actualDeliveryDate || '').dateFormat() }}

    v-col(cols="2")
      v-card
        v-card-subtitle.primary--text.text-uppercase Delivered By
        v-card-text {{ generateEmployeeName(orderData.deliveredBy) || 'N/A' }}

    v-col(cols="2")
      v-card
        v-card-subtitle.primary--text.text-uppercase Co Deliverer
        v-card-text {{ generateEmployeeName(orderData.co_deliverer) || 'N/A' }}

    v-col(cols="4")
      v-card
        v-card-subtitle.primary--text.text-uppercase Address
        v-card-text {{ orderData.address }}

    v-col(cols="2")
      v-card
        v-card-subtitle.primary--text.text-uppercase Shipping Fee
        v-card-text {{ (orderData.shipping_fee || 'N/A') }}

    v-col(cols="2")
      v-card
        v-card-subtitle.primary--text.text-uppercase Order Date
        v-card-text {{ (orderData.orderDate || '').dateFormat() }}

    v-col(cols="2")
      v-card
        v-card-subtitle.primary--text.text-uppercase Payment Type
        v-card-text {{ orderData.paymentType || 'N/A' }}

    v-col(cols="2")
      v-card
        v-card-subtitle.primary--text.text-uppercase Paid Date
        v-card-text {{ (orderData.paidDate || '').dateFormat() }}

    v-col(cols="2")
      v-card
        v-card-subtitle.primary--text.text-uppercase Order Status
        v-card-text {{ (orderData.status || {}).name || 'N/A' }}

    v-col(cols="2")
      v-card
        v-card-subtitle.primary--text.text-uppercase Payment Terms
        v-card-text {{ paymentTerms }}

    v-col(cols="12")
      v-card
        v-card-subtitle.primary--text.text-uppercase Notes
        v-card-text {{ orderData.notes || 'N/A' }}

  v-card.mt-5
    v-container
      v-simple-table(dense)
        template(v-slot:default)
          thead
            tr
              th Item
              th Discount
              th Status
              th Date Allocated
              th Quantity
              th Unit Price
              th Amount
          tbody
            tr(
              v-for="item in orderDetails"
              :key="item.id"
            )
              td {{ item.stock.brand_name }} {{ item.stock.description }}
              td {{ (item.discount || 0).phpFormat() }}
              td {{ item.order_detail_status_id }}
              td {{ item.date_allocated.dateFormat() }}
              td(align="right") {{ item.quantity }} {{ item.unit }}
              td(align="right") {{ (item.unit_price || 0).phpFormat() }}
              td(align="right") {{ ((item.quantity * (item.unit_price - item.discount)) || 0).phpFormat() }}
            tr
              td(align="right" colspan="6")
                strong TOTAL
              td(align="right")
                span {{ amountDue.phpFormat() }}
  v-dialog(
    v-model="paymentTransactions"
    eager
    width="800"
  )
    v-card
      v-container
        payment-transactions(
          :order-data="orderData"
          :order-details="orderDetails"
          :popup="paymentTransactions"
        )
</template>
<script>
import orderRepository from '@/repositories/order.repository'
import VueGetApi from '@/libs/classes/VueGetApi.class'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { getVars, requestVars } from '@/libs/api-helper.extra'
import appAxios from '@/libs/app-axios'


const [orderPrintRecieptVars, orderPrintReceiptVarNames] = requestVars({
  identifier: 'order-print-receipt',
  hasData: false,
})

const orderPrintReceiptHandler = new VueRequestHandler(null, orderPrintReceiptVarNames)

function paymentTermsMessage(paymentTerms) {
  if (!paymentTerms) return ''
  return [
    `PAYMENT TERMS: ${paymentTerms} days\n\n`,
    'Payment beyond the terms in days shall no longer be subjected to any discount\n\n',
    'Payment beyond 1 Month after the terms shall be subjected to 5% interest (RETAIL PRICE) per month',
  ].join('')
}

export default {
  name: 'ShowOrder',
  components: {
    paymentTransactions: () => import('./Transactions'),
  },
  data: () => ({
    ...getVars('order', {}, { list: false }),
    ...orderPrintRecieptVars,
    gettingBillingStatement: false,
    orderData: {},
    paymentTransactions: false,
    orderDetails: [],
    invoice: {},
    generatingPdf: false,
  }),
  created() {
    this.getOrder()
    this.initWebsocket()
  },
  computed: {
    updatable() {
      return true
    },
    customerAddress() {
      if (!this.orderData.customer || this.$objectEmpty(this.orderData.customer)) return 'N/A'
      const customer = this.$objectChangeKeyCase(this.orderData.customer)
      const { houseNo, street, purok, brgy, city, province, region } = customer
      const regionLabel = region ? `Region ${region}` : null
      const addressInfo = [houseNo, street, purok, brgy, city, province, regionLabel]
      return addressInfo.filter(item => Boolean(item)).join(', ')
    },
    amountDue() {
      return this.orderDetails.reduce(
        (total, item) => total + item.quantity * (item.unit_price - item.discount),
        0,
      )
    },
    paymentTerms() {
      const { paymentTerms } = this.orderData
      if (!paymentTerms) return 'COD'
      return `${paymentTerms} days`
    },
    overDue() {
      return Window.subtractDates(this.dueDate, Window.getCurrentDate())
    },
    dueDate() {
      const { deliveryDate = null, actualDeliveryDate = null, paymentTerms = 0 } = this.orderData
      if (actualDeliveryDate) return actualDeliveryDate.addDays(paymentTerms)
      if (deliveryDate) return deliveryDate.addDays(paymentTerms)
      return this.invoice?.invoice_date?.addDays?.(paymentTerms)
    },
    orderId() {
      return this.$route.params.orderId
    },
  },
  methods: {
    initWebsocket() {
      const { echo } = this.$store.state.websocket
      echo.private('database.event').listen('DBUpdateEvent', this.dbUpdateEvent)
    },
    async generateBillingStatement () {
      try {
        if (!this.generateBillingStatement) return
        this.gettingBillingStatement = true
        const { id: orderId } = this.orderData
        const { data } = await appAxios().get(`/orders/${orderId}/billing-statement`)
        const blob = new Blob([data.decodeBase64()], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.target = '__blank'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (e) {
        if (e instanceof Error) {
          console.log(e.message)
        }
        this.$swalError('oops something went wrong!!!')
      } finally {
        this.gettingBillingStatement = false
      }
    },
    getOrder() {
      if (this.orderGetting) return
      const requestVarNames = this.getRequestVarNames('order', { list: false })
      const handler = new VueGetApi(this, requestVarNames)
      const { orderId } = this.$route.params
      const repository = orderRepository.show(orderId)
      handler.fetch(repository, this.getOrderStatusOk)
    },
    dbUpdateEvent({ model, data }) {
      if (!model || this.$objectEmpty(data)) return
      if (model === 'Order') {
        this.orderData = { ...this.orderData, ...Window.objectChangeKeyCase(data) }
      }
    },
    getOrderStatusOk({ data }) {
      const orderData = {}
      Object.keys(data).forEach(key => {
        if (key === 'details') {
          this.orderDetails = data[key]
          return
        }
        if (key === 'invoice') {
          this.invoice = data[key]
        }
        orderData[key.snakeToCamelCase()] = data[key]
      })
      this.orderData = orderData
    },
    generatePdf() {
      const handler = orderPrintReceiptHandler
      const repository = orderRepository.printReceipt
      handler.setVue(this)
      handler.execute(repository, [this.orderId]).then(response => {
        const { data } = response
        const blob = new Blob([data.decodeBase64()], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.target = '__blank'
        link.click()
        URL.revokeObjectURL(link.href)
      })
    },
    getPDFTitle() {
      const invoiceNo = this.$intToInvoiceNumber(this.invoice.id) || ''
      const { customer = {} } = this.orderData
      const customerName = this.$autoAbbriviate(customer.company)
        .replace(/[.]/g, '')
        .replace(/[\s]+/g, '_') || ''
      const time = new Date().getTime()
      return ['invoice', invoiceNo, customerName, time].join('_')
    },
    getInvoiceDetails() {
      const { customer, address, deliveredBy, actualDeliveryDate, deliveryDate } = this.orderData
      const date = (actualDeliveryDate || deliveryDate || '').dateFormat()
      return {
        deliveredTo: this.$autoAbbriviate(customer.company),
        contactNo: customer.contact_no || '',
        address,
        date,
        deliveredBy: this.generateEmployeeName(deliveredBy),
      }
    },
    prepareToPrintOrderDetails() {
      const result = this.orderDetails.map(item => {
        const { unit, quantity, unitPrice, stock, discount } = this.$objectChangeKeyCase(item)
        const { description } = stock
        const amount = (quantity * (unitPrice - discount)).currencyFormat()
        return {
          unit,
          quantity,
          unitPrice: (unitPrice || 0).currencyFormat(),
          amount,
          description,
        }
      })
      for (let i = result.length; i < 14; i++) result.push([])
      return result
    },
    getRequestVarNames(baseName, params) {
      const { loading = true, error = true, list = true } = params
      const result = {}
      if (loading) result.loading = `${baseName}Getting`
      if (error) result.error = `${baseName}GetErrors`
      if (list) result.list = `${baseName}List`
      return result
    },
    generateEmployeeName(args = {}) {
      const { firstname = null, lastname = null, middlename = null } = args || {}
      if (!firstname && !lastname) return ''
      const middleInitial = middlename ? `${middlename.substr(0, 1).toUpperCase()}.` : null
      return [firstname, middleInitial, lastname].filter(item => Boolean(item)).join(' ')
    },
  },
}
</script>
